<script setup>
import SectionContainer from '@components/SectionContainer.vue'
import ResponsiveImage from '@components/ResponsiveImage.vue'
import Slider from '@components/Slider.vue'
</script>

<template>
  <SectionContainer>
    <Slider :sm="1" :md="1" :preview="false" :scroll="false" :dots="true" :arrows="true">
      <div>
        <div class="slide">
          <ResponsiveImage url="/images/donation-1-d.png" mobile-url="/images/donation-1-m.png" alt="Veteran Donation" class="image" />
          <div class="content">
            <div class="time">MARCH 16, 2024</div>
            <div class="title">Supporting Veterans When It Matters Most</div>
            <div class="description">This spring, we had the privilege of partnering with Chive Charities to donate $11,000 toward an Afghanistan war veteran's at-home treatment.</div>
            <a href="/learn/hometown-hero-contributes-11000-for-a-veterans-at-home-treatment/" class="link">LEARN MORE</a>
          </div>
        </div>
      </div>
      <div>
        <div class="slide">
          <ResponsiveImage url="/images/donation-2-d.png" mobile-url="/images/donation-2-m.png" alt="Sponsoring Dog" class="image" />
          <div class="content">
            <div class="time">OCTOBER 19, 2023</div>
            <div class="title">Sponsoring Delta-the-Dog</div>
            <div class="description">Since the beginning, Hometown Hero has been committed to supporting veterans. When we heard about the amazing work K9s For Warriors was doing to save lives, we had to help by donating $35,000 to train and pair a dog we named Delta with a veteran named Sarah.</div>
            <a href="/learn/meet-delta-dog-our-veteran-service-pup/" class="link">LEARN MORE</a>
          </div>
        </div>
      </div>
    </Slider>
  </SectionContainer>
</template>

<style scoped lang="scss">
:deep(.slider__arrows) {
  @media screen and (max-width: 767px) {
    display: none!important;
  }
}
:deep(.slider__dots) {
  @media screen and (min-width: 768px) {
    display: none!important;
  }
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
    gap: 80px;
  }

  .image {
    width: 100%;
    height: auto;
    display: block;
    max-width: 500px;
  }

  .content {
    text-align: center;

    @media screen and (min-width: 768px) {
      text-align: left;
    }

    .time {
      color: var(--Light-Orange, #EFA670);
      font-family: Outfit, serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      letter-spacing: 1.82px;
      margin-bottom: 4px;
    }

    .title {
      color: var(--Primary-600, #183D4F);
      font-family: Outfit, serif;
      font-size: 28px;
      font-weight: 700;
      line-height: 32px; /* 114.286% */
      margin-bottom: 8px;

      @media screen and (min-width: 768px) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .description {
      color: #102B38;
      font-family: Outfit, serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      margin-bottom: 16px;

      @media screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .link {
      display: block;
      border-radius: 34px;
      background: #DB7E5A;
      color: #FFF;
      font-family: Outfit, serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 32px; /* 200% */
      padding: 6px 60px;
      width: 100%;
      text-decoration: none;
      cursor: pointer;

      @media screen and (min-width: 768px) {
        width: auto;
        display: inline-block;
      }
    }
  }
}
</style>
